import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Table } from '@f2w/data-table/table-types';
import { ActionsDropdown } from '@f2w/data-table/components/ActionsDropdown';
import { Text } from 'Atoms/Text';
import { Td, Tr } from 'Atoms/Table';
import { Div } from 'Atoms/Layout';
import { Button, ButtonToolbar } from 'Atoms/Button';
import { useModalDispatcherConfirm, useToastDispatcherApi } from 'Components/Dispatcher';
import generateRoute from 'Services/Router';
import { translate } from 'Services/Translator';
import React from 'react';
import { formatMoney } from 'Services/NumberHelper';
import { BookingType, CreditorProcessStep, CreditorState } from '../enums';
import { CreditorStateFilter } from '../components/CreditorStateFilter';
import { CreditorInvoiceState } from '../components/CreditorInvoiceState';
import { DateType, MoneyType } from '@f2w/form';
import { PositionsObjectDataField } from 'Pages/Creditor/components/PositionsObjectDataField';
import { RentalObjectCard } from 'Components/App/card';
const renderObjectDataCell = (row, onPropertyClick, onBuildingClick, openPositionsModal) => {
    const { rentalObject } = row;
    if (rentalObject) {
        return (_jsx(RentalObjectCard, { rentalObjectName: rentalObject.name, rentalObjectId: rentalObject.id, buildingName: rentalObject.buildingName }));
    }
    const { property } = row;
    if (property) {
        return (_jsx(Div, { children: _jsx("a", { href: "#", onClick: (event => {
                    event.preventDefault();
                    onPropertyClick(property.id);
                }), children: property.name }) }));
    }
    const { bookingType, positions } = row;
    if (bookingType === BookingType.SEPARATED && positions.length > 0) {
        return (_jsx(PositionsObjectDataField, { positions: positions, onBuildingClick: onBuildingClick, onPropertyClick: onPropertyClick, openPositionsModal: openPositionsModal }));
    }
    return translate('notAvailable');
};
const BaseTable = Table.factory()(() => ({
    tableId: 'Creditor',
    manualControl: true,
    initialState: {
        filters: [{
                id: 'state',
                value: [
                    CreditorState.QR_PENDING,
                    CreditorState.QR_COMPLETED,
                    CreditorState.INFO_PENDING,
                    CreditorState.INFO_CONFIRMED,
                    CreditorState.INVOICE_ASSIGNED,
                    CreditorState.READY_FOR_PAYMENT,
                ],
            }],
    },
    useSortBy: {},
    useGlobalFilter: {},
    usePagination: {},
    useRowState: {},
    useRowSelect: {
        onAllRowsSelectChange: (props, isSelected) => {
            if (isSelected) {
                props.rows.forEach(row => {
                    props.setRowState(row.id, {
                        amount: row.original.amount,
                        cellState: {},
                    });
                });
            }
            else {
                props.rows.forEach(row => {
                    props.setRowState(row.id, {
                        amount: 0,
                        cellState: {},
                    });
                });
            }
        },
        onRowSelectChange: (props, isSelected) => {
            if (isSelected) {
                props.setRowState(props.row.id, {
                    amount: props.row.original.amount,
                    cellState: {},
                });
            }
            else {
                props.setRowState(props.row.id, {
                    amount: 0,
                    cellState: {},
                });
            }
        },
    },
    FiltersLayout: ({ renderFilter }) => (_jsx(_Fragment, { children: renderFilter('state', { minw: 180 }) })),
    columns: {
        $rowSelect: {
            sticky: 'left',
        },
        $actions: {
            sticky: 'left',
        },
        reference: {
            Header: translate('creditor.reference'),
            useSortBy: {},
            Cell: ({ row: { original: { reference, property, hasAccountingRecord } } }) => {
                if (reference === null) {
                    return translate('notAvailable');
                }
                if (property === null || !hasAccountingRecord) {
                    return reference;
                }
                return (_jsx("a", { href: generateRoute('view_accounting.journal', {
                        query: reference,
                    }) + `&_for=property-${property.id}`, target: '_blank', children: reference }));
            },
        },
        objectData: {
            Header: _jsx("div", { style: { overflowWrap: 'break-word' }, children: translate('creditor.propertyOrRentalObject') }),
            useSortBy: {},
            Cell: ({ row: { original }, onPropertyClick, onBuildingClick, openPositionsModal }) => renderObjectDataCell(original, onPropertyClick, onBuildingClick, openPositionsModal),
        },
        state: {
            Header: translate('creditor.state'),
            useSortBy: {},
            useFilters: {
                Filter: CreditorStateFilter,
            },
            Cell: ({ value }) => _jsx(CreditorInvoiceState, { state: value }),
        },
        label: {
            Header: translate('creditor.label'),
            maxWidth: 300,
            useSortBy: {},
        },
        paymentRecipient: {
            Header: translate('creditor.creditorName'),
            useSortBy: {},
            Cell: ({ value }) => value ?? translate('notAvailable'),
        },
        amount: {
            Header: translate('creditor.amount'),
            useSortBy: {},
            Cell: ({ value }) => _jsx(Text, { nowrap: true, children: formatMoney(value) }),
        },
        dueDate: {
            Header: translate('creditor.paymentDueTo'),
            useSortBy: {},
            Cell: ({ value }) => DateType.format(value) ?? translate('notAvailable'),
        },
        invoiceDate: {
            Header: translate('invoice.date'),
            useSortBy: {},
            Cell: ({ value }) => DateType.format(value) ?? translate('notAvailable'),
        },
    },
    Footer: ({ state }) => {
        const { rowState } = state;
        const totalAmount = Object
            .values(rowState)
            .map(state => state.amount)
            .reduce((totalAmount, amount) => amount + totalAmount, 0);
        return (_jsxs(Tr, { children: [_jsx(Td, { colSpan: 6, align: "right", children: _jsxs("strong", { children: [translate('creditor.totalAmountSelected'), ":"] }) }), _jsx(Td, { align: "left", noWrap: true, children: _jsx(Text, { children: MoneyType.useFormat(totalAmount) }) }), _jsx(Td, {}), _jsx(Td, {})] }));
    },
    RowActions: ({ row: { original: { id, state, receipt } }, onDeleteClicked, openEditForm, onManualPaymentClick, onCancelQrReader, openCreditorDetailsModal, }) => {
        return (_jsx(ActionsDropdown, { id: id, scrollable: true, quickActions: [
                {
                    icon: 'eye',
                    title: translate('creditor.view'),
                    onClick: () => openCreditorDetailsModal(id, receipt?.path)
                },
                CreditorState.isEditable(state) && {
                    icon: 'edit',
                    title: translate('btn.edit'),
                    onClick: () => openEditForm({
                        id: id,
                        receipt: receipt,
                        currentStep: CreditorProcessStep.fromState(state),
                        initialStep: CreditorProcessStep.fromState(state),
                        state: state,
                    })
                }
            ], dropdownActions: ({ Link }) => (_jsxs(_Fragment, { children: [CreditorState.isPayable(state) && (_jsx(Link, { icon: "dollar-sign", label: translate('creditor.actions.pay'), onClick: () => onManualPaymentClick(id) })), receipt && _jsx(Link, { icon: 'download', label: translate('documentsManager.actions.download'), href: receipt.path, download: receipt.name }), CreditorState.isDeletable(state) && (_jsx(Link, { icon: 'trash', label: translate('btn.delete'), onClick: () => onDeleteClicked(id) })), state === CreditorState.QR_PENDING && (_jsx(Link, { icon: 'cancelQr', label: translate('creditor.actions.cancelQrReader'), onClick: () => onCancelQrReader(id) }))] })) }));
    },
    BulkActions: ({ state: { selectedRowIds }, onBulkBankFilesClick, onManualPaymentsClick }) => {
        const ids = Object.keys(selectedRowIds).map(id => Number(id));
        const disabled = !ids || Object.keys(ids).length === 0;
        return (_jsxs(ButtonToolbar, { variant: "outline", color: disabled ? 'neutral' : 'primary', children: [_jsx(Button, { icon: 'plus', label: translate('creditor.actions.createBankFile'), title: translate('creditor.actions.createBankFile'), onClick: () => onBulkBankFilesClick(ids), disabled: disabled }), _jsx(Button, { icon: 'plus', label: translate('creditor.bankFiles.action.markAsPaid'), title: translate('creditor.bankFiles.action.markAsPaid'), onClick: () => onManualPaymentsClick(ids), disabled: disabled })] }));
    },
}));
export const CreditorDataTable = Object.assign(BaseTable, {
    use({ creditorServices, onRefresh, openBulkInvoicesErrorModal, openBulkPaymentModal, openManualPaymentForm, onBuildingClick, onPropertyClick, openEditForm, openPositionsModal, openCreditorDetailsModal, }) {
        const { success, error } = useToastDispatcherApi();
        const onDeleteCreditorInvoice = React.useCallback((id) => {
            creditorServices.deleteCreditorInvoice(id)
                .then(() => {
                success({
                    timeout: 2,
                    message: translate('creditor.delete.success')
                });
                onRefresh();
            })
                .catch(({ response }) => {
                const errorMessage = (response.status === 400)
                    ? response.data.message
                    : translate('creditor.unableToDelete');
                error({
                    timeout: 5,
                    message: errorMessage
                });
            });
        }, [onRefresh]);
        const onCancelQrReader = React.useCallback((id) => {
            creditorServices.cancelQrReader(id)
                .then(() => {
                success({
                    timeout: 2,
                    message: translate('creditor.actions.cancelQrReader.success')
                });
                onRefresh();
            })
                .catch(() => {
                error({
                    timeout: 5,
                    message: translate('creditor.cancelQrReader.error')
                });
            });
        }, [onRefresh]);
        const onBulkBankFilesClick = React.useCallback((invoiceIds) => {
            creditorServices.bulkBankFiles(invoiceIds)
                .then(() => {
                success({
                    timeout: 2,
                    message: translate('creditor.bankFiles.created')
                });
                onRefresh();
            })
                .catch((errorRq) => {
                if (errorRq.isAxiosError && errorRq.response) {
                    const { response: { data, status } } = errorRq;
                    if (status === 400 && data.errorCode === 'INVOICES_WITH_INVALID_DATA') {
                        openBulkInvoicesErrorModal(data.numberOfInvoicesInPast, data.numberOfCorrectInvoices, data.invalidBankLinks, invoiceIds);
                    }
                    else if (status === 400) {
                        error({
                            timeout: 5,
                            message: errorRq.response.data.message
                        });
                    }
                }
                else {
                    error({
                        timeout: 5,
                        message: translate('creditor.bankFiles.unableToCreate')
                    });
                }
            });
        }, [onRefresh, openBulkInvoicesErrorModal]);
        const onDeleteConfirmation = useModalDispatcherConfirm({
            icon: 'alert-triangle',
            onConfirm: onDeleteCreditorInvoice,
            message: translate('creditor.delete.areYouSure'),
            help: translate('creditor.delete.confirmation'),
            btnConfirm: translate('btn.confirm')
        });
        return CreditorDataTable.useTable({
            onBulkBankFilesClick,
            onManualPaymentsClick: openBulkPaymentModal,
            onCancelQrReader,
            onPropertyClick,
            onBuildingClick,
            onDeleteClicked: onDeleteConfirmation,
            openEditForm,
            onManualPaymentClick: openManualPaymentForm,
            openCreditorDetailsModal,
            openPositionsModal
        }, () => ({
            getData: ({ request }) => creditorServices.listCreditorInvoices({ ...request }),
        }));
    }
});
