import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useMemo } from 'react';
import { isFunction, isString, toObject, useAsyncDebounce } from '@f2w/utils';
import { useDispatcherApi } from 'Components/Dispatcher/useDispatcher';
import { translate } from 'Services/Translator';
import { useStateManager } from 'react-select';
import BaseReactSelect from 'react-select/base';
import { useAsync as useReactAsync } from 'react-select/async';
import { UseOption } from './types';
import { selectComponents, useVars } from './selectTheme';
export * from './types';
const BaseSelect = (iProps) => {
    const [themeVars, props] = useVars(iProps);
    let selectProps = useStateManager(props);
    selectProps = withConfirm({
        ...selectProps,
        components: {
            ...selectComponents,
            ...selectProps.components,
        },
        theme: { vars: themeVars },
    });
    return _jsx(BaseReactSelect, { ...selectProps });
};
const SyncProvider = (props) => {
    const options = Array.isArray(props.defaultOptions) ? [...props.defaultOptions, ...props.options] : props.options;
    const defaultValue = useMemo(() => props.defaultValue !== undefined ? UseOption.findOptions(props.defaultValue, options) : undefined, [props.defaultValue, options]);
    const value = useMemo(() => props.value != null ? UseOption.findOptions(props.value, options) : props.value, [props.value, options]);
    const isSearchable = useMemo(() => {
        if (props.isSearchable == null) {
            if (options[0]?.['options']) {
                let optionsLength = 0;
                for (let v of options) {
                    optionsLength += (v['options']?.length ?? 0);
                    if (optionsLength > 10)
                        return true;
                }
            }
            else if (options?.length > 10) {
                return true;
            }
        }
        return props.isSearchable ?? false;
    }, [props.isSearchable, options]);
    return BaseSelect({
        noOptionsMessage: ({ inputValue }) => translate('select.noEntries'),
        ...props,
        defaultValue,
        value,
        options,
        isSearchable,
    });
};
const AsyncProvider = (props) => {
    const loadOptions = useAsyncDebounce(props.loadOptions, 350);
    const asyncProps = useReactAsync({
        noOptionsMessage: ({ inputValue }) => !inputValue ? translate('select.startTyping') : translate('select.noResults'),
        ...props,
        loadOptions,
    });
    return BaseSelect(asyncProps);
};
export const UseSelect = (props) => {
    const isAsync = props.loadOptions;
    const Component = isAsync ? AsyncProvider : SyncProvider;
    const selectProps = {
        placeholder: translate('select.placeholder'),
        ...props,
    };
    return _jsx(Component, { ...selectProps });
};
UseSelect.displayName = 'UseSelect';
UseSelect.defaultProps = {
    isMulti: false,
    getOptionLabel: (a) => {
        return a.label;
    }
};
function withConfirm({ confirm, ...props }) {
    const dispatcher = useDispatcherApi();
    const onChange = useCallback((currentValue, meta) => {
        if (isFunction(confirm)) {
            confirm = confirm({
                current: currentValue,
                prev: props.value,
            });
        }
        if (confirm) {
            dispatcher.modal.show({
                btnConfirm: translate('btn.confirm'),
                message: translate('Are you sure?'),
                ...(isString(confirm) ? { message: confirm } : toObject(confirm)),
                onConfirm: () => {
                    props.onChange?.(currentValue, meta);
                },
            });
            return;
        }
        props.onChange?.(currentValue, meta);
    }, [props.value]);
    return {
        ...props,
        onChange,
    };
}
