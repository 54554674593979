import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMemo } from 'react';
import { DeepChat } from 'deep-chat-react';
import styled, { css } from 'styled-components';
import { useTheme } from '@f2w/theme';
import { useChatBotApi } from 'Components/ChatBot/useChatBot';
import { Button } from 'Atoms/Button';
import { Icon } from 'Atoms/Icon';
import { translate } from 'Services/App';
import { Text } from 'Atoms/Text';
import { renderToString } from 'react-dom/server';
import { FlexCol } from 'Atoms/Layout';
import { AvatarIcon } from 'Atoms/Icon/AvatarIcon';
import { getCurrentUser } from 'Services/UserServices';
export const ChatBot = (props) => {
    const api = useChatBotApi(props);
    const user = getCurrentUser();
    return _jsx(_Fragment, { children: _jsxs(ChatPositioner, { children: [api.show && _jsx(ChatWindow, { userImage: user?.profileImage, userName: user?.firstName && `${user.firstName} ${user.lastName}`, ...props, api: api }), _jsx(Button, { icon: 'bot', onClick: api.toggle, rounded: true, style: { padding: 16, fontSize: 35 } })] }) });
};
ChatBot.defaultProps = {
    placeholder: 'Sag mir, wie ich dir helfen kann...',
    introMessage: `Hallo ich bin Walter KI.
      Wie kann ich dir heute helfen?`,
};
function ChatWindow({ api, ...props }) {
    const theme = useTheme();
    const styles = useMemo(() => {
        const primaryTheme = {
            color: theme.palette.white,
            backgroundColor: theme.palette.primary.$500,
        };
        const neutralTheme = {
            color: theme.palette.neutral.$500,
            backgroundColor: theme.palette.neutral.$50,
        };
        const _chat = {
            style: {
                border: 'none',
                width: '100%',
                height: '620px',
            }
        };
        const searchHeight = '32px';
        _chat.inputAreaStyle = {
            borderTop: '1px solid var(--fw-color-neutral-100)',
            padding: '0 16px',
            boxSizing: 'border-box',
            scale: '1',
        };
        _chat.textInput = {
            placeholder: {
                text: props.placeholder,
                style: {
                    color: 'var(--fw-color-neutral-100)',
                },
            },
            styles: {
                container: {
                    ...theme.typography.medium.fontSizeStyles,
                    boxShadow: 'none',
                    border: 'none',
                    margin: '12px 0',
                    width: 'auto',
                    flex: '1',
                    scale: '1',
                    height: searchHeight,
                },
            },
        };
        _chat.submitButtonStyles = {
            position: 'inside-right',
            alwaysEnabled: false,
            submit: {
                container: {
                    default: {
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '6px',
                        margin: '0',
                        borderRadius: '16px',
                        fontSize: '20px',
                        bottom: 'auto',
                        top: '12px',
                        boxSizing: 'border-box',
                        scale: '1',
                        ...primaryTheme,
                    },
                },
                svg: {
                    styles: {
                        default: {
                            display: 'block',
                            width: '1em',
                            height: '1em',
                            filter: 'brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7%) hue-rotate(240deg) brightness(102%) contrast(102%)',
                        },
                    },
                },
            },
            loading: {
                container: {
                    default: { justifyContent: 'start' },
                },
                svg: {
                    styles: {
                        default: {
                            filter: 'brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7%) hue-rotate(240deg) brightness(102%) contrast(102%)',
                            width: '.25em',
                            height: '.25em',
                            bottom: 'auto',
                            left: 'calc(-9990px)',
                        }
                    },
                },
            },
            disabled: {
                container: {
                    default: { ...neutralTheme },
                    hover: { cursor: 'default' }
                },
                svg: {
                    styles: {
                        default: { filter: 'none', ...neutralTheme }
                    }
                },
            },
        };
        const avatarSize = '22px';
        const avatarIconSrc = () => {
            let styles = {
                backgroundColor: neutralTheme.color,
                margin: '0',
                width: '24px',
                height: '24px',
                padding: '2px',
                borderRadius: '16px',
            };
            if (props.userImage)
                return {
                    src: props.userImage,
                    styles: {
                        avatar: {
                            ...styles,
                            width: '28px',
                            height: '28px',
                            padding: '0',
                        }
                    },
                };
            if (props.userName)
                return {
                    src: iconSrc(_jsx(AvatarIcon, { text: props.userName, size: avatarSize, color: neutralTheme.backgroundColor })),
                    styles: { avatar: styles },
                };
            return {
                src: iconSrc(_jsx(Icon, { icon: 'user', size: avatarSize, color: neutralTheme.backgroundColor })),
                styles: { avatar: styles },
            };
        };
        _chat.avatars = {
            default: {
                styles: {
                    avatar: { height: avatarSize, width: avatarSize },
                },
            },
            ai: {
                src: iconSrc(_jsx(Icon, { icon: 'bot', size: avatarSize, color: primaryTheme.backgroundColor })),
            },
            user: avatarIconSrc(),
        };
        _chat.messageStyles = {
            default: {
                shared: {
                    bubble: {
                        borderRadius: 'var(--fw_chat-borderRadius)',
                        padding: '8px',
                        ...neutralTheme,
                    },
                },
                ai: {
                    bubble: {
                        ...primaryTheme,
                    },
                },
            },
            intro: {
                bubble: {
                    ...primaryTheme,
                },
            },
            loading: {
                message: {
                    styles: {
                        bubble: {
                            padding: 'revert-layer',
                            ...primaryTheme,
                        },
                    }
                }
            }
        };
        return _chat;
    }, []);
    const chatProps = api.use();
    return _jsxs(ChatWindowRoot, { children: [_jsxs("header", { children: [_jsxs("div", { "data-slot": 'title', children: [_jsx(Icon, { icon: 'bot' }), _jsxs(FlexCol, { children: [_jsx(Text, { children: translate('Walter') }), _jsx(Text, { small: true, children: translate('AI Support-Assistent') })] })] }), _jsx(Button.Toolbar, { "data-slot": 'actions', color: 'light', variant: 'link', children: _jsx(Button, { icon: 'x', onClick: api.hideChat }) })] }), _jsx(DeepChat, { ...styles, ...chatProps })] });
}
const ChatWindowRoot = styled.div(({ theme }) => {
    return css `
        --fw_chat-width: 540px;
        --fw_chat-borderRadius: 6px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 1px solid var(--fw-color-neutral-100);
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.15);
        width: var(--fw_chat-width);
        border-radius: var(--fw_chat-borderRadius);

        > header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            align-self: stretch;
            background: var(--fw-color-primary-500);
            color: var(--fw-color-white);
            padding: 12px 16px;

            [data-slot] {
                display: flex;
                align-items: center;
                gap: 8px;
            }

            [data-slot=title] {
                ${theme.typography.body.fontSizeStyles};

                .fw-icon {
                    font-size: 22px;
                }
            }

            [data-slot=actions] {
                .fw-btn {
                    --btn-min-height: 20px;
                    font-size: 20px;
                    padding: 0;
                    color: var(--fw-color-white);
                }
            }
        }
    `;
});
const ChatPositioner = styled.div(({ theme, offset = 25 }) => {
    let position = {
        bottom: offset,
        right: offset,
    };
    return css `
        position: fixed;
        white-space: nowrap;
        z-index: 99999;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        ${position};
        gap: 20px;
    `;
});
function iconSrc(component) {
    return `data:image/svg+xml;utf8,${encodeURIComponent(renderToString(component))}`;
}
