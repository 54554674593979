import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { BaseFormTypeOld, BooleanType, DateType, DateValueType, NumberType, SelectType, StringType, TextType, } from '@f2w/form-new';
import Portfolio from 'Models/Portfolio';
import { translate } from 'Services/App';
import { useMemo } from 'react';
import { Div, FlexCol } from 'Atoms/Layout';
import { DateType as OldDateType, DecimalType } from '@f2w/form';
import { roundToNearestPointX, roundToPrecision } from 'Utils/number';
import RentServices from 'Pages/Rent/services';
import moment from 'moment/moment';
import { RentChangeTemplate, RentModificationStepTemplate, SelectionStepTemplate, SummaryStepTemplate, } from '../components/ContractModificationWizardTemplate';
export var ContractModificationWizardType;
(function (ContractModificationWizardType) {
    ContractModificationWizardType.roundDifferenceOptions = [
        {
            label: '5.00',
            value: 5.00
        },
        {
            label: '1.00',
            value: 1.00
        },
        {
            label: '0.05',
            value: 0.05
        },
        {
            label: '0.01',
            value: 0.01
        }
    ];
    class SelectionStep extends BaseFormTypeOld {
        getSettings = (v) => {
            return v.parent.value.settings;
        };
        updateSelectedContractData = (v) => {
            const { contracts } = v.value.settings ?? { contracts: [] };
            const contractId = v.value.contractId;
            const foundContract = contracts.find(c => c.id === contractId);
            v.updateValue({
                selectedContract: foundContract,
                validFrom: moment(foundContract?.possibleSuggestedDate).toDate()
            }, true);
        };
        buildFields() {
            const portfolio = Portfolio.instance();
            return {
                propertyId: SelectType.number({
                    label: translate('property'),
                    required: true,
                    choices: () => {
                        return useMemo(() => {
                            return portfolio.getProperties().map(property => ({
                                label: property.name,
                                value: property.id
                            }));
                        }, [portfolio.getProperties()]);
                    },
                    onChange: (typeValue) => {
                        typeValue.parent.children.buildingId.updateValue(null, false, false);
                    }
                }),
                buildingId: SelectType.number({
                    label: translate('building.title'),
                    required: true,
                    choices: (v) => {
                        const buildings = v.parent.value.propertyId
                            ? portfolio.getBuildingsByPropertyId(v.parent.value.propertyId)
                            : [];
                        return useMemo(() => {
                            return buildings.map(building => ({
                                label: building.name,
                                value: building.id
                            }));
                        }, [buildings]);
                    },
                    onChange: (typeValue) => {
                        typeValue.parent.children.rentalObjectId.updateValue(null, false, false);
                    }
                }),
                rentalObjectId: SelectType.number({
                    label: translate('rentalObject'),
                    required: true,
                    choices: (v) => {
                        const rentalObjects = v.parent.value.buildingId
                            ? portfolio.getRentalObjectsByBuildingId(v.parent.value.buildingId)
                            : [];
                        return useMemo(() => {
                            return rentalObjects.map(rentalObject => ({
                                label: rentalObject.name,
                                value: rentalObject.id
                            }));
                        }, [rentalObjects]);
                    },
                    onChange: (typeValue) => {
                        typeValue.parent.children.contractId.updateValue(null, false, false);
                    }
                }),
                contractId: SelectType.number({
                    label: translate('contract'),
                    required: true,
                    choices: (v) => {
                        const { contracts } = this.getSettings(v);
                        const { rentalObjectId } = v.parent.value;
                        return useMemo(() => {
                            return contracts.filter(c => c.rentalObjectId === rentalObjectId).map(contract => ({
                                label: contract.label,
                                value: contract.id
                            }));
                        }, [contracts, rentalObjectId]);
                    },
                    onChange: (typeValue) => {
                        const parent = typeValue.parent;
                        this.updateSelectedContractData(parent);
                    }
                }),
                validFrom: new DateType({
                    label: translate('contract.validFrom'),
                    required: true,
                    onChange: (typeValue) => {
                        const selectedContract = typeValue.parent.value.selectedContract;
                        if (selectedContract) {
                            const { referenceInterestRateDate } = this.getSettings(typeValue);
                            const rentModificationStep = typeValue.root().children.rentModificationStep;
                            rentModificationStep.children.rentPotentialDate.updateOptions({
                                min: selectedContract.earliestPossibleReferenceInterestDate,
                                max: referenceInterestRateDate
                            });
                            rentModificationStep.updateValue({
                                rentPotential: null,
                                rentPotentialDate: moment(referenceInterestRateDate).toDate()
                            });
                            typeValue.updateOptions({
                                min: selectedContract.possibleStartDate,
                                max: selectedContract.lastPossibleStartDate,
                            });
                        }
                    },
                    help: translate('contract.validFrom.help'),
                    helperText: (valueType) => {
                        const date = DateValueType.castMoment(valueType.parent.value?.selectedContract?.possibleSuggestedDate)?.add(-1, 'day');
                        return translate('contract.validFrom.helperText', { date: DateValueType.format(date) });
                    },
                }),
                netRentChanges: new BooleanType({
                    hideLabel: true,
                    content: translate('contract.rentChanges'),
                    help: translate('contract.rentChanges.help'),
                    required: false,
                    defaultValue: true,
                    onChange: (v) => {
                        if (!v.value) {
                            if (!v.parent.value.ancillaryExpenseChanges && !v.parent.value.otherChanges) {
                                v.updateValue(true, true, false);
                            }
                        }
                    }
                }),
                ancillaryExpenseChanges: new BooleanType({
                    hideLabel: true,
                    content: translate('contract.ancillaryExpenseChanges'),
                    required: false,
                    disabled: true,
                    onChange: (v) => {
                        if (!v.value) {
                            if (!v.parent.value.netRentChanges && !v.parent.value.otherChanges) {
                                v.updateValue(true, true, false);
                            }
                        }
                    }
                }),
                otherChanges: new BooleanType({
                    hideLabel: true,
                    content: translate('contract.otherChanges'),
                    required: false,
                    disabled: true,
                    onChange: (v) => {
                        if (!v.value) {
                            if (!v.parent.value.netRentChanges && !v.parent.value.ancillaryExpenseChanges) {
                                v.updateValue(true, true, false);
                            }
                        }
                    }
                }),
            };
        }
        _renderWidget(props) {
            return _jsx(SelectionStepTemplate, { ...props });
        }
    }
    ContractModificationWizardType.SelectionStep = SelectionStep;
    class RentModificationStep extends BaseFormTypeOld {
        static calculateDifference = (typeValue) => {
            const total = typeValue.value.rentPotential.totalAmount;
            const roundDifference = typeValue.children.roundDifference.value;
            return DecimalType.format(roundToPrecision(Math.abs(total - roundToNearestPointX(total, roundDifference))));
        };
        static calculateAdjustmentAmount = (typeValue, rentPotential) => {
            const rentPotentialToUse = rentPotential || typeValue.value.rentPotential;
            if (!rentPotentialToUse) {
                return 0;
            }
            const roundDifference = typeValue.children.roundDifference.value;
            if (typeValue.value.useChangeOfReferenceInterest &&
                typeValue.value.useInflationIndexChange &&
                typeValue.value.useCostIncreaseChange) {
                const total = rentPotentialToUse.totalAmount;
                return roundToNearestPointX(total, roundDifference);
            }
            const options = [
                { active: typeValue.value.useChangeOfReferenceInterest, amount: rentPotentialToUse.possibleReferenceInterestAmount },
                { active: typeValue.value.useInflationIndexChange, amount: rentPotentialToUse.possibleInflationAmount },
                { active: typeValue.value.useCostIncreaseChange, amount: rentPotentialToUse.costIncreaseAmount }
            ];
            const calculatedTotal = options
                .filter(option => option.active)
                .reduce((sum, option) => sum + option.amount, 0);
            return roundToNearestPointX(calculatedTotal, roundDifference);
        };
        static getReasonText(typeValue, rentPotential) {
            const rentPotentialToUse = rentPotential || typeValue.value.rentPotential;
            if (!rentPotentialToUse) {
                return "";
            }
            const options = [
                {
                    active: typeValue.value.useChangeOfReferenceInterest,
                    text: translate('contractModification.reason.referenceIndexRate', {
                        oldReferenceInterest: `${rentPotentialToUse.currentReferenceInterest}%`,
                        newReferenceInterest: `${rentPotentialToUse.possibleReferenceInterest}%`,
                        interestChangeInPercent: `${DecimalType.format(rentPotentialToUse.possibleReferenceInterestPercent)}%`,
                    })
                },
                {
                    active: typeValue.value.useInflationIndexChange,
                    text: translate('contractModification.reason.consumerPriceIndex', {
                        oldIndex: rentPotentialToUse.currentInflationPoints,
                        newIndex: rentPotentialToUse.possibleInflationPoints,
                        indexChangeInPercent: `${DecimalType.format(rentPotentialToUse.possibleInflationPercent)}%`,
                    })
                },
                {
                    active: typeValue.value.useCostIncreaseChange,
                    text: translate('contractModification.reason.costIncrease', {
                        oldLastSettlement: OldDateType.format(rentPotentialToUse.currentRateDate),
                        newLastSettlement: OldDateType.format(rentPotentialToUse.potentialRateDate),
                        flatRate: rentPotentialToUse.yearlyFlatRatePercent,
                        costIncreasePercent: `${DecimalType.format(rentPotentialToUse.costIncreasePercent)}%`
                    })
                }
            ];
            const activeTexts = options
                .filter(option => option.active)
                .map((option) => option.text);
            if (activeTexts.length === 3) {
                return translate('contractModification.allReasons', {
                    reasonOne: activeTexts[0],
                    reasonTwo: activeTexts[1],
                    reasonThree: activeTexts[2],
                });
            }
            if (activeTexts.length === 2) {
                return translate('contractModification.twoReasons', {
                    reasonOne: activeTexts[0],
                    reasonTwo: activeTexts[1],
                });
            }
            if (activeTexts.length === 1) {
                return translate('contractModification.oneReason', {
                    reason: activeTexts[0],
                });
            }
            return "";
        }
        buildFields() {
            return {
                useRentPotential: new BooleanType({
                    hideLabel: true,
                    content: translate('contract.modification.useRentPotential'),
                    required: false,
                    help: (field) => {
                        const selectionStep = field.root().children.selectionStep;
                        if (selectionStep.value.selectedContract?.isRentPotentialPossible) {
                            return translate('contract.modification.useRentPotential.help');
                        }
                        return (_jsxs(FlexCol, { justifyFlexStart: true, children: [_jsx(Div, { style: { textAlign: 'start' }, children: translate('contract.modification.useRentPotential.dataMissing.help') }), _jsxs("ul", { children: [_jsx("li", { style: { textAlign: 'start' }, children: translate('contract.modification.useRentPotential.help.lastReferenceInterestRate') }), _jsx("li", { style: { textAlign: 'start' }, children: translate('contract.modification.useRentPotential.help.consumerPriceIndexBase') }), _jsx("li", { style: { textAlign: 'start' }, children: translate('contract.modification.useRentPotential.help.finalCostCalculation') })] })] }));
                    },
                    defaultValue: true,
                    isDisabled: (v) => !v.root().children.selectionStep.value.selectedContract?.isRentPotentialPossible,
                    onChange: (v) => {
                        const p = v.parent;
                        if (!v.value) {
                            p.updateValue({
                                rentPotentialDate: null,
                                rentPotential: null
                            });
                        }
                        else {
                            const selectionStep = v.root().children.selectionStep;
                            const referenceInterestRateDateMoment = moment(selectionStep.value.settings?.referenceInterestRateDate);
                            const today = moment();
                            p.updateValue({
                                rentPotentialDate: referenceInterestRateDateMoment.isBefore(today)
                                    ? referenceInterestRateDateMoment.toDate()
                                    : today.toDate()
                            });
                        }
                    }
                }),
                rentPotentialDate: new DateType({
                    label: translate('contract.modification.rentPotentialDate'),
                    required: false,
                    type: 'month',
                    format: 'MM.yyyy',
                    jsonFormat: 'MM.yyyy',
                    isVisible: (v) => {
                        return v.parent.value.useRentPotential && !v.parent.children.useRentPotential.isDisabled;
                    },
                    isDisabled: (v) => {
                        return !v.parent.value.useRentPotential && !v.parent.children.useRentPotential.isDisabled;
                    },
                    onChange: async (v) => {
                        const selectedContract = v.root().children.selectionStep.value.selectedContract;
                        if (!v.value || !selectedContract || v.parent.children.useRentPotential.isDisabled) {
                            return;
                        }
                        const p = v.parent;
                        RentServices.getCalculatedRentPotentialForDate(selectedContract.lastEffectiveRentId, v.formattedValue.toString()).then(data => {
                            p.updateValue({
                                rentPotential: data,
                                adjustmentAmount: RentModificationStep.calculateAdjustmentAmount(p, data),
                                reason: RentModificationStep.getReasonText(p, data)
                            });
                        })
                            .catch((e) => {
                            if (e.isAxiosError && e.response?.status === 400) {
                                v.setError(e.response.data.message);
                                return;
                            }
                            throw e;
                        });
                    },
                }),
                adjustmentAmount: new NumberType({
                    hideLabel: true,
                    required: true
                }),
                roundDifference: new NumberType({
                    required: true,
                    defaultValue: 5.00
                }),
                reason: new TextType({
                    label: translate('rentModification.explanation'),
                    required: true,
                }),
                reservation: new StringType({
                    label: translate('rent.reservation'),
                    required: false
                }),
                hasSubsidies: new BooleanType({
                    required: false,
                    defaultValue: false
                }),
                useChangeOfReferenceInterest: new BooleanType({
                    defaultValue: true,
                    hideLabel: true,
                    content: translate('contract.rent.changeOfReferenceInterest'),
                    onChange: (v) => {
                        const p = v.parent;
                        p.updateValue({
                            adjustmentAmount: RentModificationStep.calculateAdjustmentAmount(p),
                            reason: RentModificationStep.getReasonText(p)
                        });
                    },
                    Widget: ({ typeValue, children }) => {
                        const rentPotential = typeValue.parent.value.rentPotential;
                        return _jsx(RentChangeTemplate, { current: _jsxs(_Fragment, { children: [moment(rentPotential.currentRateDate).format('MM.YYYY'), ": ", rentPotential.currentReferenceInterest, "%"] }), potential: _jsxs(_Fragment, { children: [moment(rentPotential.potentialRateDate).format('MM.YYYY'), ": ", rentPotential.possibleReferenceInterest, "%"] }), possible: {
                                percent: rentPotential.possibleReferenceInterestPercent,
                                amount: rentPotential.possibleReferenceInterestAmount,
                            }, children: children });
                    }
                }),
                useInflationIndexChange: new BooleanType({
                    hideLabel: true,
                    content: translate('contract.rent.inflationIndexChange'),
                    defaultValue: true,
                    onChange: (v) => {
                        const p = v.parent;
                        p.updateValue({
                            adjustmentAmount: RentModificationStep.calculateAdjustmentAmount(p),
                            reason: RentModificationStep.getReasonText(p)
                        });
                    },
                    Widget: ({ typeValue, children }) => {
                        const rentPotential = typeValue.parent.value.rentPotential;
                        return _jsx(RentChangeTemplate, { current: _jsxs(_Fragment, { children: [moment(rentPotential.currentInflationRateDate).format('MM.YYYY'), ": ", rentPotential.currentInflationPoints, "%"] }), potential: _jsxs(_Fragment, { children: [moment(rentPotential.potentialInflationRateDate).format('MM.YYYY'), ": ", rentPotential.possibleInflationPoints, "%"] }), possible: {
                                percent: rentPotential.possibleInflationPercent,
                                amount: rentPotential.possibleInflationAmount,
                            }, children: children });
                    }
                }),
                useCostIncreaseChange: new BooleanType({
                    hideLabel: true,
                    defaultValue: true,
                    content: (typeValue) => (_jsxs(_Fragment, { children: [translate('rent-potential-calculator.cost-increase'), ": ", DecimalType.format((typeValue.parent.value.rentPotential).yearlyFlatRatePercent), "% ", translate('perYear')] })),
                    onChange: (v) => {
                        const p = v.parent;
                        p.updateValue({
                            adjustmentAmount: RentModificationStep.calculateAdjustmentAmount(p),
                            reason: RentModificationStep.getReasonText(p)
                        });
                    },
                    Widget: ({ typeValue, children }) => {
                        const rentPotential = typeValue.parent.value.rentPotential;
                        return _jsx(RentChangeTemplate, { current: moment(rentPotential.currentCostSettlementDate).format('MM.YYYY'), potential: moment(rentPotential.potentialCostSettlementDate).format('MM.YYYY'), possible: {
                                percent: rentPotential.costIncreasePercent,
                                amount: rentPotential.costIncreaseAmount,
                            }, children: children });
                    }
                })
            };
        }
        _renderWidget(props) {
            return _jsx(RentModificationStepTemplate, { ...props });
        }
    }
    ContractModificationWizardType.RentModificationStep = RentModificationStep;
    class SummaryStep extends BaseFormTypeOld {
        buildFields() {
            return {};
        }
        _renderWidget(props) {
            return _jsx(SummaryStepTemplate, { ...props });
        }
    }
    ContractModificationWizardType.SummaryStep = SummaryStep;
})(ContractModificationWizardType || (ContractModificationWizardType = {}));
