import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useRef, useState } from 'react';
import { translate } from 'Services/Translator';
import generateRoute from 'Services/Router';
import client from 'Utils/client';
import RentTableModel from './RentTableModel';
import PageOverview from '@f2w/data-table/layout/PageOverview';
import { ActiveView, useObjectViewModalContext } from 'Components/ObjectViewModal';
import { getScopeFilterLevel, getScopeFilters, getScopePropertyId } from 'Services/ScopeManager';
import { FormModal } from '@f2w/form';
import { usePortfolio } from 'Components/Base/PortfolioContext';
import { RentPotentialModal } from 'Pages/Contract/components/RentPotentialModal';
import ContractServices from 'Pages/Contract/services/contractServices';
import { RentalObjectFilter } from 'Pages/Rent/components/RentalObjectFilter';
import { BuildingFilter } from 'Pages/Rent/components/BuildingFilter';
import { DateFilter } from 'Pages/Rent/components/DateFilter';
import { useRenterMirrorDateExportForm, } from 'Pages/Rent/forms/RenterMirror/RenterMirrorDateExportForm/useRenterMirrorDateExportForm';
import { useRenterMirrorDateRangeExportForm, } from 'Pages/Rent/forms/RenterMirror/RenterMirrorDateRangeExportForm/useRenterMirrorDateRangeExportForm';
import { useRenterMirrorTenantListExportForm, } from 'Pages/Rent/forms/RenterMirror/RenterMirrorDateExportForm/useRenterMirrorTenantListExportForm';
import { Button, Dropdown, DropdownButton } from 'Components/Atoms';
import { ExcelIcon2, PdfIcon2 } from 'Components/App/Icons';
import { ContractVacancyFilter } from "Pages/Contract/components/ContractVacancyFilter";
import RentServices from "Pages/Rent/services";
import { useToastDispatcherApi } from "Components/Dispatcher";
import { hasFeature } from "Services/FeatureFlagManager";
import { PersonModal } from "Pages/Person/components/PersonModal";
import { getGlobalContext } from "Services/GlobalContext";
function useRentsTable({ filters, onViewRent, onDeleteRent, openPersonInfoModal, showRentPotential, buildingFilterOptions, rentalObjectFilterOptions, scopeFilterLevel, openRenterMirrorDateExportForm, openRenterMirrorDateRangeExportForm, openRenterMirrorTenantListExportForm }) {
    const disableBuildingFilter = scopeFilterLevel !== 'property';
    const tableModel = React.useMemo(() => RentTableModel.create({
        manualControl: true,
        initialState: {
            filters: filters ? Object.keys(filters).map(key => ({ id: key, value: filters[key] })) : []
        },
        Actions: () => {
            if (!hasFeature('NEW_CONTRACT_CHANGE')) {
                return _jsx(_Fragment, {});
            }
            const { scope } = getGlobalContext();
            let route = "/app/wizard/contract-modification/undefined";
            switch (scope?.level) {
                case "property":
                    route += `?_for=property-${scope.id}`;
                    break;
                case "rentalObject":
                    route += `?_for=rentalObject-${scope.id}`;
                    break;
            }
            return (_jsx(Button, { icon: 'plus', href: route, children: translate('rent.newModification') }));
        },
        BulkActions: () => {
            return (_jsxs(DropdownButton, { label: translate('document.rent.renterMirror.general.title', {}, 'documents'), children: [_jsxs(Dropdown.Item, { color: "dark", onClick: () => openRenterMirrorDateExportForm('excel'), children: [_jsx(ExcelIcon2, {}), " ", translate('document.rent.renterMirror.dateReportXls.title', {}, 'documents')] }), _jsxs(Dropdown.Item, { color: "dark", onClick: () => openRenterMirrorDateExportForm('pdf'), children: [_jsx(PdfIcon2, {}), " ", translate('document.rent.renterMirror.dateReportPdf.title', {}, 'documents')] }), _jsxs(Dropdown.Item, { color: "dark", onClick: () => openRenterMirrorDateRangeExportForm('excel'), children: [_jsx(ExcelIcon2, {}), " ", translate('document.rent.renterMirror.dateRangeReportXls.title', {}, 'documents')] }), _jsxs(Dropdown.Item, { color: "dark", onClick: () => openRenterMirrorDateRangeExportForm('pdf'), children: [_jsx(PdfIcon2, {}), " ", translate('document.rent.renterMirror.dateRangeReportPdf.title', {}, 'documents')] }), _jsxs(Dropdown.Item, { color: "dark", onClick: () => openRenterMirrorTenantListExportForm('excel'), children: [_jsx(ExcelIcon2, {}), " ", translate('document.rent.renterMirror.tenantListReportXls.title', {}, 'documents')] }), _jsxs(Dropdown.Item, { color: "dark", onClick: () => openRenterMirrorTenantListExportForm('pdf'), children: [_jsx(PdfIcon2, {}), " ", translate('document.rent.renterMirror.tenantListReportPdf.title', {}, 'documents')] })] }));
        },
        columns: {
            buildingId: {
                hide: true,
                Header: translate('building.title'),
                useFilters: {
                    Filter: BuildingFilter,
                    disableFilters: disableBuildingFilter,
                },
            },
            rentalObjectId: {
                hide: true,
                Header: translate('rentalObject'),
                useFilters: {
                    Filter: RentalObjectFilter,
                    disableFilters: disableBuildingFilter,
                },
            },
            showVacancy: {
                Header: translate('contract.overview.filter.showVacancy.label'),
                useFilters: {
                    Filter: ContractVacancyFilter
                },
                hide: true
            },
            reportingStart: {
                hide: true,
                Header: translate('accounting.reportingDate'),
                useFilters: {
                    Filter: DateFilter,
                }
            },
        }
    }), []);
    const getData = React.useCallback(async ({ request }) => {
        const url = generateRoute('api.rents.list');
        const { data } = await client.get(url, { params: request });
        return data;
    }, []);
    return tableModel.createRemote({
        getData,
        showRentPotential,
        onDeleteRent,
        openPersonInfoModal,
        onViewRent,
        buildingFilterOptions,
        rentalObjectFilterOptions,
    });
}
const RentPage = () => {
    const { openViewModalObj, activeView } = useObjectViewModalContext();
    const portfolio = usePortfolio();
    const personModalApi = useRef();
    const [buildingOptions, setBuildingOptions] = useState([]);
    const [rentalObjectOptions, setRentalObjectOptions] = useState([]);
    const scopeFilterLevel = getScopeFilterLevel();
    const initialLoad = React.useRef(true);
    useEffect(() => {
        if (initialLoad.current) {
            initialLoad.current = false;
            return;
        }
        if (!activeView) {
            instance.refresh();
        }
    }, [activeView]);
    const renterMirrorDateExportForm = useRenterMirrorDateExportForm();
    const renterMirrorDateRangeExportForm = useRenterMirrorDateRangeExportForm();
    const renterMirrorTenantListExportForm = useRenterMirrorTenantListExportForm();
    const { success, error } = useToastDispatcherApi();
    const instance = useRentsTable({
        filters: {
            showVacancy: '1',
        },
        onViewRent: (rent) => {
            openViewModalObj({ id: rent.id, view: ActiveView.RENT, contractId: rent.contract.id, parentRentalObjectId: rent.rentalObject.id });
        },
        showRentPotential: (rentId) => {
            setRentIdForPotential(rentId);
        },
        openRenterMirrorDateExportForm: (type) => {
            let scopeFilters = getScopeFilters(portfolio);
            renterMirrorDateExportForm.openForm({
                type,
                ...scopeFilters
            });
        },
        openRenterMirrorDateRangeExportForm: (type) => {
            let scopeFilters = getScopeFilters(portfolio);
            renterMirrorDateRangeExportForm.openForm({
                type,
                ...scopeFilters
            });
        },
        openRenterMirrorTenantListExportForm: (type) => {
            let scopeFilters = getScopeFilters(portfolio);
            renterMirrorTenantListExportForm.openForm({
                type,
                ...scopeFilters
            });
        },
        onDeleteRent: (id) => {
            RentServices.deleteRent(id)
                .then(() => {
                success({
                    message: translate('rent.successfullyDeleted')
                });
                instance.refresh();
            })
                .catch((e) => {
                if (e.isAxiosError && e.response.status === 400) {
                    error({
                        message: translate(e.response.data.message)
                    });
                    return;
                }
                throw e;
            });
        },
        buildingFilterOptions: buildingOptions,
        rentalObjectFilterOptions: rentalObjectOptions,
        scopeFilterLevel,
        openPersonInfoModal: personId => personModalApi.current?.openModal(personId)
    });
    const buildingFilterValue = instance.state.filters.find(({ id }) => id === 'buildingId')?.value;
    const selectedPropertyId = getScopePropertyId(portfolio);
    useEffect(() => {
        if (scopeFilterLevel !== 'property' || !selectedPropertyId) {
            setBuildingOptions([]);
            return;
        }
        const options = portfolio.getBuildingsByPropertyId(selectedPropertyId)
            .map(building => {
            return {
                value: building.id,
                label: building.name,
            };
        });
        options.unshift({ value: 0, label: translate('rent.state.all') });
        setBuildingOptions(options);
    }, [selectedPropertyId, scopeFilterLevel]);
    useEffect(() => {
        if (!buildingFilterValue) {
            setRentalObjectOptions([]);
            instance.setFilter('rentalObjectId', null);
            return;
        }
        const rentalObjects = portfolio.getRentalObjectsByBuildingId(buildingFilterValue);
        const rentalObjectColumn = instance.columns.find(c => c.id === 'rentalObjectId');
        instance.setFilter('rentalObjectId', 0);
        if (!rentalObjects.find(r => r.id == rentalObjectColumn.filterValue)) {
            instance.setFilter('rentalObjectId', 0);
        }
        const options = rentalObjects
            .map(building => {
            return {
                value: building.id,
                label: building.name,
            };
        });
        options.unshift({ value: 0, label: translate('rent.state.all') });
        setRentalObjectOptions(options);
    }, [buildingFilterValue]);
    const [rentIdForPotential, setRentIdForPotential] = React.useState();
    return (_jsxs(_Fragment, { children: [_jsx(PageOverview, { instance: instance, showFilters: true, title: translate('rents.title') }), _jsx(RentPotentialModal, { rentId: rentIdForPotential, onCloseModal: () => setRentIdForPotential(null), getRentPotentialForRentId: ContractServices.getRentPotentialForRentId }), _jsx(FormModal, { modal: renterMirrorDateExportForm }), _jsx(FormModal, { modal: renterMirrorDateRangeExportForm }), _jsx(FormModal, { modal: renterMirrorTenantListExportForm }), _jsx(PersonModal, { api: personModalApi, onClose: () => instance.refresh() })] }));
};
export default RentPage;
