import { useMemo, useRef } from 'react';
import { getUuid, LocalStorage } from '@f2w/utils';
export function useChatStore(timeout = 4 * 60 * 1000) {
    const store = useMemo(() => new LocalStorage({
        key: 'fw_chat'
    }), []);
    const sessionRef = useRef();
    const getSessionId = () => sessionRef.current?.sessionId ?? getSession().sessionId;
    const createSession = () => {
        return sessionRef.current = store.set({
            sessionId: getUuid(),
            messages: [],
        });
    };
    const getSession = () => {
        let session = sessionRef.current ?? store.get() ?? store.set({
            sessionId: getUuid(),
            messages: [],
        });
        if (session?.updateTimestamp) {
            const now = (new Date()).valueOf();
            const time = now - session.updateTimestamp;
            const expired = time > timeout;
            if (expired) {
                return {
                    ...createSession(),
                    expired: true,
                };
            }
        }
        return sessionRef.current = session;
    };
    const updateSession = (messages) => {
        let session = getSession();
        messages.forEach(message => {
            session.messages.push(message);
            if (message.role === 'api')
                session.updateTimestamp = (new Date()).valueOf();
        });
        store.set(session);
        return session;
    };
    const clearSession = () => {
        store.remove();
        sessionRef.current = null;
    };
    const api = {
        ref: sessionRef,
        get current() {
            return getSession();
        },
        getId: getSessionId,
        get: getSession,
        update: updateSession,
        clear: clearSession,
    };
    return api;
}
