import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { translate } from 'Services/App';
import { Div, FlexCol, FlexRow } from 'Atoms/Layout';
import { Text } from 'Atoms/Text';
import { ContractModificationWizardType } from '../types/ContractModificationWizardType';
import moment from 'moment';
import { Callout } from 'Atoms/Callout';
import { Icon } from 'Atoms/Icon';
import { DateType as OldDateType, DecimalType, MoneyType } from '@f2w/form';
import { RadioButtonGroup } from 'Components/Molecules/RadioButtonGroup';
import { roundToNearestPointX } from 'Utils/number';
import { OverlayTrigger, Tooltip } from 'Atoms/Overlay';
import { Checkbox } from 'Atoms/Input/controls';
import { usePortfolio } from 'Components/Base/PortfolioContext';
import { useWizardContext } from '@f2w/view';
import { Button } from 'Atoms/Button';
export const SelectionStepTemplate = ({ field }) => {
    return (_jsxs(FlexCol, { gap: 20, children: [_jsxs(FlexCol, { gap: 8, children: [_jsx(Text, { h6: 500, neutral: 900, children: translate('contract.selection') }), _jsx(Text, { medium: 400, neutral: 500, children: translate('contract.selection.help') })] }), _jsxs(FlexCol, { children: [field.children.propertyId.render(), field.children.buildingId.render(), field.children.rentalObjectId.render(), field.children.contractId.render(), field.children.validFrom.render()] }), _jsxs(FlexCol, { gap: 20, p: 24, b: "1px solid #D7D8DB", brd: 8, children: [_jsxs(FlexCol, { gap: 4, children: [_jsx(Text, { body: 500, neutral: 900, children: _jsx("strong", { children: translate('contract.modification') }) }), _jsx(Text, { medium: 400, neutral: 500, children: translate('contract.modification.help') })] }), _jsxs(FlexCol, { children: [field.children.netRentChanges.render(), field.children.ancillaryExpenseChanges.render(), field.children.otherChanges.render()] })] })] }));
};
export const RentChangeTemplate = ({ current, potential, possible, children, }) => (_jsx(_Fragment, { children: _jsxs(FlexCol, { gap: 10, children: [children, _jsxs(FlexRow, { children: [_jsxs(FlexRow, { gap: 16, pl: 35, flex: '70%', alignCenter: true, children: [_jsx(Div, { flex: '0 1 30%', children: _jsx(Text, { medium: 400, neutral: 900, children: current }) }), _jsx(Icon, { icon: 'arrow-right', size: 22 }), _jsx(Text, { medium: 400, primary: 500, children: potential })] }), _jsxs(FlexRow, { justifySpaceBetween: true, flex: '30%', children: [_jsxs(Text, { medium: 400, neutral: 900, children: [DecimalType.format(possible.percent), "%"] }), _jsx(Text, { medium: 400, neutral: 900, children: DecimalType.format(possible.amount) })] })] })] }) }));
export const RentModificationStepTemplate = ({ field }) => {
    const selectionStep = field.root().children.selectionStep;
    const selectedContract = selectionStep.value.selectedContract;
    const rentPotential = field.value.rentPotential;
    const showCallout = rentPotential !== null
        && moment(rentPotential.potentialRateDate).isAfter(moment(rentPotential.potentialInflationRateDate));
    const roundingDifference = ContractModificationWizardType.RentModificationStep.calculateDifference(field);
    const adjustmentAmount = ContractModificationWizardType.RentModificationStep.calculateAdjustmentAmount(field, rentPotential);
    return (_jsxs(FlexCol, { gap: 20, children: [_jsxs(FlexCol, { gap: 8, children: [_jsx(Text, { h6: 500, neutral: 900, children: translate('rentModification.title') }), _jsx(Text, { medium: 400, neutral: 500, children: translate('rentModification.title.help') })] }), _jsxs(FlexCol, { gap: 16, children: [_jsxs(FlexCol, { b: '1px solid #D7D8DB', brd: 8, children: [_jsx(Div, { p: 24, bb: '1px solid #D7D8DB', children: field.children.useRentPotential.render() }), field.value.useRentPotential && !field.children.useRentPotential.isDisabled && (_jsxs(FlexCol, { gap: 20, p: 24, children: [_jsxs("div", { children: [field.children.rentPotentialDate.render(), showCallout && (_jsx(Callout, { message: translate('rentModification.consumerPriceIndexNotAvailable') }))] }), field.value.rentPotentialDate && rentPotential && (_jsxs("div", { children: [field.children.useChangeOfReferenceInterest.render(), field.children.useInflationIndexChange.render(), field.children.useCostIncreaseChange.render()] }))] })), field.value.useRentPotential && field.value.rentPotentialDate && field.value.rentPotential && (_jsxs(FlexCol, { p: 24, bt: '1px solid #D7D8DB', gap: 24, children: [_jsxs(FlexRow, { children: [_jsx(FlexRow, { gap: 16, flex: '70%', children: _jsx(Text, { medium: 500, neutral: 900, children: translate('contractModification.rent.subTotal') }) }), _jsxs(FlexRow, { justifySpaceBetween: true, flex: '30%', children: [_jsxs(Text, { medium: 400, neutral: 900, children: [DecimalType.format(field.value.rentPotential.totalAmountPercent), "%"] }), _jsx(Text, { medium: 400, neutral: 900, children: DecimalType.format(field.value.rentPotential.totalAmount) })] })] }), _jsx(FlexRow, { children: _jsxs(FlexRow, { gap: 16, justifySpaceBetween: true, alignCenter: true, flexGrow: 1, children: [_jsx(Text, { medium: 500, neutral: 900, children: translate('contractModification.rent.roundingDifference') }), _jsx(RadioButtonGroup, { onChange: (value) => {
                                                        field.updateValue({
                                                            roundDifference: value,
                                                            adjustmentAmount: roundToNearestPointX(field.value.rentPotential.totalAmount, value)
                                                        });
                                                    }, radioOptions: ContractModificationWizardType.roundDifferenceOptions, selectedValue: field.value.roundDifference }), _jsx(Text, { medium: 400, neutral: 900, children: roundingDifference })] }) }), _jsxs(FlexRow, { children: [_jsx(FlexRow, { gap: 16, flex: '70%', children: _jsx(Text, { medium: 500, neutral: 900, children: translate('contract.rent.total') }) }), _jsx(FlexRow, { justifyFlexEnd: true, flex: '30%', children: _jsx(Text, { medium: 400, neutral: 900, children: DecimalType.format(adjustmentAmount) }) })] })] }))] }), selectedContract.lastEffectiveRentReservation && (_jsx(_Fragment, { children: _jsx(Callout, { icon: 'alert-circle', type: 'neutral', message: _jsxs(_Fragment, { children: [_jsx(Text, { small: 500, neutral: 500, children: translate('contract.rent.previousReservation', {
                                            date: OldDateType.format(selectionStep.value.validFrom)
                                        }) }), _jsx("br", {}), _jsx(Text, { children: selectedContract.lastEffectiveRentReservation })] }) }) })), _jsxs(FlexCol, { p: 24, gap: 16, b: "1px solid #D7D8DB", brd: 8, children: [_jsxs(FlexRow, { alignCenter: true, justifySpaceBetween: true, children: [_jsx(Text, { medium: 500, neutral: 900, children: translate('contract.rent.existingNetRent') }), _jsx(Text, { medium: 400, neutral: 900, children: MoneyType.format(selectedContract.lastEffectiveRentNetAmount) })] }), _jsxs(FlexRow, { alignCenter: true, justifySpaceBetween: true, children: [_jsxs(FlexRow, { gap: 5, children: [_jsx(Text, { medium: 500, neutral: 900, children: translate('contract.rent.adjustment') }), _jsx(OverlayTrigger, { overlay: _jsx(Tooltip, { id: 'rent-adjustment-help', children: translate('contract.rent.adjustment.help') }), children: _jsx(Icon, { icon: 'help-circle', size: 20 }) })] }), field.children.adjustmentAmount.render()] }), _jsxs(FlexRow, { alignCenter: true, justifySpaceBetween: true, children: [_jsx(Text, { medium: 500, neutral: 900, children: translate('contract.rent.newNetRent') }), _jsx(Text, { medium: 400, neutral: 900, children: MoneyType.format(selectedContract.lastEffectiveRentNetAmount + (field.value.adjustmentAmount ?? 0.0)) })] })] })] }), _jsxs("div", { children: [field.children.reason.render({
                        props: {
                            height: 100
                        }
                    }), field.children.reservation.render(), _jsxs(FlexRow, { gap: 8, children: [_jsx(Text, { medium: 400, neutral: 900, children: translate('contract.rent.hasSubsidies') }), _jsxs(FlexRow, { gap: 16, children: [_jsxs(FlexRow, { gap: 8, alignCenter: true, children: [_jsx(Checkbox, { onChange: () => field.children.hasSubsidies.updateValue(true, true, false), checked: field.value.hasSubsidies, radio: true, name: 'has_subsidies' }), " ", translate('yes')] }), _jsxs(FlexRow, { gap: 8, alignCenter: true, children: [_jsx(Checkbox, { onChange: () => field.children.hasSubsidies.updateValue(false, true, false), checked: !field.value.hasSubsidies, radio: true, name: 'has_subsidies' }), " ", translate('no')] })] })] })] })] }));
};
export const SummaryStepTemplate = ({ field }) => {
    const portfolio = usePortfolio();
    const selectionStep = field.root().children.selectionStep;
    const rentModificationStep = field.root().children.rentModificationStep;
    const rentPotential = rentModificationStep.value.rentPotential;
    const selectedContract = selectionStep.value.selectedContract;
    const rentalObjectName = portfolio.getRentalObjectById(selectedContract.rentalObjectId).name;
    const buildingName = portfolio.getBuildingByRentalObjectId(selectedContract.rentalObjectId).name;
    const propertyName = portfolio.getPropertyByRentalObjectId(selectedContract.rentalObjectId).name;
    const { stepApi } = useWizardContext();
    return (_jsxs(FlexCol, { gap: 40, children: [_jsxs(FlexCol, { gap: 8, children: [_jsx(Text, { h6: 500, neutral: 900, children: translate('rentModification.summary.title') }), _jsx(Text, { medium: 400, neutral: 900, children: translate('rentModification.summary.title.help') })] }), _jsxs(FlexCol, { gap: 16, children: [_jsxs(FlexCol, { gap: 24, p: 24, b: '1px solid #D7D8DB', brd: 8, children: [_jsxs(FlexRow, { alignCenter: true, justifySpaceBetween: true, children: [_jsx(Text, { large: true, neutral: 900, children: translate('contract') }), _jsx(Button.Edit, { "$size": 'sm', onClick: () => {
                                            stepApi.goToStep("contractSelectionStep");
                                        } })] }), _jsxs(FlexCol, { gap: 16, children: [_jsx(SummaryBlockItem, { label: translate('property'), value: propertyName }), _jsx(SummaryBlockItem, { label: translate('building.title'), value: buildingName }), _jsx(SummaryBlockItem, { label: translate('rentalObject'), value: rentalObjectName }), _jsx(SummaryBlockItem, { label: translate('contract.from'), value: OldDateType.format(selectedContract.contractStart) }), _jsx(SummaryBlockItem, { label: translate('contractModification.validFrom'), value: OldDateType.format(selectionStep.value.validFrom) })] })] }), _jsxs(FlexCol, { gap: 24, p: 24, b: '1px solid #D7D8DB', brd: 8, children: [_jsxs(FlexRow, { alignCenter: true, justifySpaceBetween: true, children: [_jsx(Text, { large: true, neutral: 900, children: translate('contract.details.rent.netAmount') }), _jsx(Button.Edit, { "$size": 'sm', onClick: () => {
                                            stepApi.goToStep("rentModificationStep");
                                        } })] }), _jsxs(FlexCol, { gap: 16, children: [rentPotential && (_jsxs(_Fragment, { children: [_jsx(SummaryBlockItem, { label: translate('contract.rent.changeOfReferenceInterest'), value: DecimalType.format(rentPotential.possibleReferenceInterestAmount) }), _jsx(SummaryBlockItem, { label: translate('contract.rent.inflationIndexChange'), value: DecimalType.format(rentPotential.possibleInflationAmount) }), _jsx(SummaryBlockItem, { label: `${translate('rent-potential-calculator.cost-increase')}: ${DecimalType.format(rentPotential.yearlyFlatRatePercent)}% ${translate('perYear')}`, value: DecimalType.format(rentPotential.costIncreaseAmount) }), _jsx("hr", {})] })), _jsx(SummaryBlockItem, { label: translate('contract.rent.existingNetRent'), value: DecimalType.format(selectedContract.lastEffectiveRentNetAmount) }), _jsx(SummaryBlockItem, { label: translate('contract.rent.adjustment'), value: DecimalType.format(rentModificationStep.value.adjustmentAmount) }), _jsx(SummaryBlockItem, { label: translate('contract.rent.newNetRent'), value: DecimalType.format(selectedContract.lastEffectiveRentNetAmount + rentModificationStep.value.adjustmentAmount) })] })] })] })] }));
};
const SummaryBlockItem = ({ label, value }) => (_jsxs(FlexRow, { alignCenter: true, justifySpaceBetween: true, children: [_jsx(Text, { medium: 500, neutral: 900, children: label }), _jsx(Text, { medium: 400, neutral: 900, children: value })] }));
