const dot = require('dot-object');
const Mustache = require('mustache');
function escapeRegExp(str) {
    return str.replace(/[/\-\\^$*+?.()|[\]{}]/g, '\\$&');
}
function replaceVars(str, vars) {
    const varNames = Object.keys(vars)
        .map((varName) => escapeRegExp(varName))
        .join('|');
    const re = new RegExp('{{(' + varNames + ')}}', 'gi');
    return str.replace(re, function (_, templateVar) {
        return vars[templateVar];
    });
}
function addDataCounters(htmlString) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    let sectionCounter = 0;
    doc.querySelectorAll(".contract-section").forEach((section) => {
        sectionCounter++;
        let subsectionCounter = 0;
        const h2 = section.querySelector("h2");
        if (h2) {
            h2.setAttribute("data-counter", `${sectionCounter}.`);
        }
        section.querySelectorAll(".contract-subsection").forEach((subsection) => {
            subsectionCounter++;
            const h3 = subsection.querySelector("h3");
            if (h3) {
                h3.setAttribute("data-counter", `${sectionCounter}.${subsectionCounter}.`);
            }
        });
    });
    return doc.body.innerHTML;
}
export function renderTemplate(template, vars) {
    let html = Mustache.render(template, vars);
    const dotVars = dot.dot(vars);
    html = replaceVars(html, dotVars);
    html = addDataCounters(html);
    return html;
}
