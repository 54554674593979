import { useMemo, useRef, useState } from 'react';
import { isNumLike, setDebugVariable, toNum } from '@f2w/utils';
import { sleep } from 'Utils/delay';
import client from 'Utils/client';
import { generate } from 'Services/Router';
import { useChatStore } from 'Components/ChatBot/ChatStore';
async function chatSendService(data) {
    if (data.message) {
        if (isNumLike(data.message)) {
            await sleep(toNum(data.message));
        }
        return client
            .post(generate('api.chat.send'), data)
            .then(response => response.data);
    }
}
export function useChatBotApi(props) {
    const [show, setShow] = useState(props.defaultShow ?? false);
    const toggle = () => show ? hideChat() : showChat();
    const showChat = () => {
        if (!show) {
            setShow(true);
        }
    };
    const hideChat = () => {
        if (show) {
            setShow(false);
        }
    };
    const chatRef = useRef();
    const _session = useChatStore(props.sessionTimeout);
    const handleUpdate = async (body, signals) => {
        const { sessionId, expired } = _session.get();
        if (expired) {
            await signals.onResponse({ text: `New session id ${sessionId}` });
        }
        try {
            const messages = body.messages;
            const response = await chatSendService({
                sessionId,
                message: messages?.shift()?.text
            });
            if (response?.message) {
                return signals.onResponse({ text: response.message, role: 'api' });
            }
        }
        catch (error) {
            return signals.onResponse({ error: 'Error' });
        }
    };
    const useChat = () => {
        const { messages } = _session.get();
        const history = useMemo(() => messages ?? [], []);
        setDebugVariable({
            chatRef,
        });
        return {
            ref: chatRef,
            introMessage: {
                text: props.introMessage,
            },
            onMessage: ({ message, isHistory }) => {
                if (!isHistory) {
                    _session.update([message]);
                }
            },
            history,
            connect: { handler: handleUpdate }
        };
    };
    return {
        show,
        showChat,
        hideChat,
        toggle,
        use: useChat,
    };
}
