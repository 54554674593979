import { isFunction } from 'lodash';
const maybeWindow = typeof window === 'undefined' ? undefined : window;
const KEY_PREFIX = '@fwa/';
const get_key = (key) => `${KEY_PREFIX}${key}`;
export class LocalStorage {
    props;
    constructor(props) {
        this.props = props;
    }
    get key() {
        return this.props.key;
    }
    has() {
        return LocalStorage.has(this.key);
    }
    remove() {
        return LocalStorage.remove(this.key);
    }
    get(defaultValue) {
        return LocalStorage.get(this.key, defaultValue);
    }
    set(value) {
        return LocalStorage.set(this.key, value);
    }
    static has(key) {
        return maybeWindow?.localStorage.getItem(get_key(key)) != null;
    }
    static get(key, defaultValue) {
        const value = maybeWindow?.localStorage.getItem(get_key(key));
        if (value == null)
            return defaultValue;
        try {
            return JSON.parse(value);
        }
        catch (_) {
            return defaultValue;
        }
    }
    static set(key, value) {
        const newValue = isFunction(value) ? value(LocalStorage.get(key)) : value;
        maybeWindow?.localStorage.setItem(get_key(key), JSON.stringify(newValue));
        return newValue;
    }
    static remove(key) {
        if (LocalStorage.has(key)) {
            maybeWindow?.localStorage.removeItem(get_key(key));
            return true;
        }
    }
}
