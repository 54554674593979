import { useFormModal } from "@f2w/form";
import { AssetType } from "Pages/Asset/forms/AssetType";
import { translate } from "Services/Translator";
import { getGlobalContext } from "Services/GlobalContext";
import moment from "moment";
export const useAssetForm = ({ portfolio, getAssetById, createAsset, updateAsset, onClose, }) => useFormModal({
    name: 'asset-form',
    type: () => new AssetType(),
    title: ({ values }) => {
        return values?.id ? translate('asset.form.title.edit') : translate('asset.form.title.create');
    },
    onLoad: async (id) => {
        if (id !== -1) {
            const data = await getAssetById(id);
            return {
                id,
                ...data
            };
        }
        const { scope } = getGlobalContext();
        switch (scope.level) {
            case "property": {
                return {
                    propertyId: scope.id
                };
            }
            case "rentalObject": {
                return {
                    propertyId: portfolio.getPropertyByRentalObjectId(scope.id).id,
                    buildingId: portfolio.getBuildingByRentalObjectId(scope.id).id,
                    rentalObjectId: scope.id
                };
            }
        }
        return null;
    },
    onSave: (values) => {
        const formattedValues = {
            ...values,
            buildYear: values.buildYear ? Number(moment(values.buildYear).format('YYYY')) : null,
        };
        delete formattedValues.propertyId;
        if (values.id) {
            return updateAsset(values.id, formattedValues)
                .then(onClose);
        }
        return createAsset(formattedValues)
            .then(onClose);
    }
});
