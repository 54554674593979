import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Section } from 'Atoms/Layout/components';
import { useDebitorInvoiceActions } from '../../../hooks/useDebitorInvoiceActions';
import styled from 'styled-components';
import { RenderMode } from "Atoms/Dropdown/helpers";
import { hasFeature } from "Services/FeatureFlagManager";
import generateRoute from "Services/Router";
export const DocumentsTab = ({ view: { data: debitorInvoice } }) => {
    const menu = useDebitorInvoiceActions({
        invoiceId: debitorInvoice.id,
        mandateName: debitorInvoice.mandateName,
        allParties: debitorInvoice.hasMultipleParties,
        allPersons: debitorInvoice.hasMultiplePersons,
        withQr: debitorInvoice.supportsQr,
        reminder: debitorInvoice.reminderState,
        download: true,
    });
    return (_jsx(Section, { children: _jsxs(MenuWrapper, { className: 'fw-menu--light', children: [menu.render({ renderMode: RenderMode.Flat }), hasFeature('TEMPLATES_V2') && _jsxs(_Fragment, { children: [_jsx("hr", {}), debitorInvoice.documentsV2.map((document) => _jsx("div", { children: _jsx("a", { href: generateRoute('download_debitor_invoice.v2', {
                                    debitorInvoice: debitorInvoice.id,
                                    template: document.template
                                }), children: document.name }) }))] })] }) }));
};
const MenuWrapper = styled.div `
    && {
        padding: 0;
        --menu-divider-color: ${p => p.theme.palette.neutral.$100};

    --menu-color: ${p => p.theme.palette.neutral.$700};
    --menu-background-color: ${p => p.theme.palette.white};
    //--menu-border-color: ${p => p.theme.palette.neutral.$100};

    --menu-link-color: ${p => p.theme.palette.neutral.$500};

    --menu-hover-color: ${p => p.theme.palette.primary.$500};
    --menu-hover-background-color: ${p => p.theme.palette.primary.$50};

    --menu-disabled-color: ${p => p.theme.palette.neutral.$300};
    // --menu-disabled-background-color: ${p => p.theme.palette.neutral.$50};
    //--menu-disabled-border-color: white;

    --menu-header-color: ${p => p.theme.palette.neutral.$600};
    }

    .fw-dropdown-header {
      margin-top: 10px;
      ${p => p.theme.typography.body.css({ fontWeight: 500 })};
      color: ${p => p.theme.palette.neutral.$700};
      text-transform: none;
    }
    .fw-dropdown-item {
    }
`;
