import { jsx as _jsx } from "react/jsx-runtime";
import { FormControl } from 'Atoms/Input';
import { SimpleType } from './BaseSimpleType';
import { BooleanValueType } from '../../core';
import { InputField } from '../../theme';
import { orFunction, OrFunction } from '@f2w/utils';
export class BooleanType extends SimpleType {
    specs = this._getSpecs();
    constructor(options) {
        super(options);
        this.__construct();
    }
    _getSpecs() {
        return new BooleanValueType({});
    }
    createValue(props) {
        return this.specs.createValueType(this, props);
    }
    _renderForm(valueType, props) {
        const label = (OrFunction(valueType.options.content, valueType) ?? (valueType.showLabel && valueType.label)) || null;
        return _jsx(InputField, { ...props, Widget: ({ field, value, ...props }) => {
                const help = (valueType.showLabel || !valueType.options.help) ? undefined : orFunction(valueType.options.help, valueType);
                return (_jsx(FormControl.Checkbox, { label: label, checked: !!value, help: help, ...props }));
            } });
    }
}
